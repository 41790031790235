.menu_new {
  padding-bottom: 200px;
  &_name {
    color: var(--text-primary);
    font-size: 18px;
    font-weight: 500;
  }
  &_input {
    display: flex;
    background-color: #f8f9fc;
    border-radius: 8px;
    padding: 8px;
    height: 56px;
    margin-top: 12px;
    input {
      flex: 1;
      border: none;
      background-color: transparent;
    }
  }
  &_status {
    p {
      color: #1d2433;
      font-size: 18px;
      font-weight: 500;
    }
    margin-bottom: 24px;
    margin-top: 24px;
  }
  &_options {
    margin-top: 24px;
    p {
      color: #1d2433;
      font-weight: 18px;
      font-weight: 500;
    }
    &_checkbox {
      display: flex;
      .checkbox-menu {
        margin-right: 24px;
      }
    }
  }
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #389e0d; /* Change the background color when checked */
  border-color: #389e0d; /* Change the border color when checked */
}

.custom-checkbox .ant-checkbox-checked::after {
  border-color: #389e0d; /* Change the checkmark color */
}

.custom-modal .ant-modal-content {
  border-radius: 16px; /* Đặt giá trị radius bạn muốn, ví dụ: 16px */
}

.custom-modal {
  &-title {
    text-align: center;
    color: #333741;
    font-weight: 600;
    font-size: 20px;
  }
  &-content {
    text-align: center;
    margin-top: 20px;
    color: #1d2433;
    font-size: 16px;
  }
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      position: absolute;
      right: -12px;
      top: -6px;
    }
  }
}

.menu_new_input,
.custom-form-item .ant-form-item-control-input-content {
  background-color: transparent;
}

/* Tăng kích thước của checkbox */
.custom-checkbox .ant-checkbox-inner {
  width: 23px;
  height: 23px;
  border-radius: 3px;
  border-color: #333741;
}

.custom-checkbox .ant-checkbox-inner::after {
  width: 8px;
  height: 8px;
}

.custom-checkbox {
  font-size: 16px;
}

.custom-checkbox {
  margin-right: 40px !important;
  margin-bottom: 20px !important;
}

.ant-checkbox > span {
  margin-bottom: -4px !important;
  margin-left: 5px;
  font-size: 16px;
  color: #333741;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #333741 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox:after,
.ant-checkbox:hover:after {
  visibility: hidden !important;
}
