.menu_new {
  padding-bottom: 200px;
  &_name,
  .ant-col ant-form-item-label {
    color: #1d2433;
    font-size: 18px;
    font-weight: 500;
  }
  &_input,
  .ant-form-item-control-input-content {
    display: flex;
    background-color: #f8f9fc;
    border-radius: 8px;
    padding: 12px;
    margin-top: 12px;
    outline: none;
    input {
      flex: 1;
      border: none;
      background-color: transparent;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  &_status {
    p {
      color: #1d2433;
      font-size: 18px;
      font-weight: 500;
    }
    margin-bottom: 24px;
    margin-top: 24px;
  }
  &_options {
    margin-top: 24px;
    p {
      color: #1d2433;
      font-weight: 18px;
      font-weight: 500;
    }
    &_checkbox {
      display: flex;
      .checkbox-menu {
        margin-right: 24px;
      }
    }
  }
}

.menu_new_tim_picker {
  margin-top: 24px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  &_days {
    display: flex;
    justify-content: space-between;
    &_item {
      width: calc(100% / 7 - 16px);
      background-color: #f8f9fc;
      border-radius: 8px;
      color: #333;
      text-align: center;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      cursor: pointer;
    }
  }

  &_item {
    width: 40%;
    cursor: pointer;
    p {
      font-size: 18px;
    }
    &_select {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f8f9fc;
      font-size: 18px;
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 8px;
      border-radius: 8px;
    }
  }
}
.timkeeper-modal {
  .ant-modal-content {
    background-color: transparent; // Làm nền trong suốt
    box-shadow: none; // Loại bỏ đổ bóng (nếu cần)
  }

  .ant-modal-header {
    background-color: transparent; // Làm tiêu đề trong suốt
    border-bottom: none; // Loại bỏ đường viền dưới của tiêu đề (nếu cần)
  }

  .ant-modal-body {
    background-color: transparent; // Làm nội dung modal trong suốt
  }

  .ant-modal-footer {
    background-color: transparent; // Làm footer trong suốt (nếu có)
    border-top: none; // Loại bỏ đường viền trên của footer (nếu cần)
  }
}
.ant-btn-primary {
  border: none !important;
  border-radius: 5px !important ;
}
