.login-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0b111d;
  .login-top {
    height: "auto";
    background: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-box {
      background: var(--form-background);
      padding: 16px;
      border-radius: 16px;
      .err {
        span {
          display: inline-block;
          width: 367px;
          text-align: left;
        }
      }
      .ant-form-item {
        &:nth-child(3) {
          margin-bottom: 10px;
        }
      }
      .ant-form-item-explain-error {
        color: #ffffff;
      }
      .login-input {
        width: 367px;
        height: 54px;
        background: #191919 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        border-radius: 8px;
        border: 0;
        margin-top: 8px;
        color: white;
        .ant-input {
          color: white;
        }
      }
      .ant-input-password {
        .ant-input {
          background: transparent;
        }
      }
      .ant-input-password-icon {
        color: var(--text-primary) !important;
        font-size: 20px;
      }
      .login-input-light {
        width: 367px;
        height: 54px;
        background: #eaecf0 0% 0% no-repeat padding-box;
        box-shadow: 0px;
        opacity: 1;
        border-radius: 8px;
        border: 0;
        margin-top: 8px;
        color: #000 !important;
        .ant-input {
          color: #000 !important;
        }
      }
    }
  }

  .login-button-container {
    .login-button {
      background: var(--primary-6) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 9px;
      opacity: 1;
      width: 367px;
      height: 58px;
      outline: none;
      border: none;
      text-align: center;
      font-family: sans-serif;
      font-weight: 600;
      letter-spacing: 0.16px;
      color: #000;
      opacity: 1;
    }
  }
}

.login_logo {
  filter: invert(100%);
}
.header-title {
  display: flex;
  align-items: center;
  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    letter-spacing: 0.16px;
    color: var(--primary-6);
    font-weight: 600;
    font-size: 34px;
  }
  margin-bottom: 30px;
}

.light {
  background: #f3f3f3 !important;
  color: black !important;
}
.box-light {
  background: white !important;
}

.input-name-text {
  font-weight: 400;
  font-size: 17px;
  color: white;
}
.lightText {
  color: black !important;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color: var(--primary-6) !important;
  border-color: var(--primary-6) !important;
}