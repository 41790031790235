@import "/src/styles/index.scss";
@import "/src/styles/_variables.scss";

.header-bottom {
  // padding: 40px 0px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-bottom-left {
    color: $red-color;
    font-weight: bold; /* For bold text */
    font-size: 2.5rem;
    line-height: 25px;
    font-style: normal;
    letter-spacing: 0.16px;
    font-weight: bold;
    text-indent: 5px;
    display: flex;
    align-items: center;
  }
  .header-bottom-right {
    display: flex;
    width: 45%;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    justify-content: flex-end;
    .search-bar {
      width: 60%;
      .ant-input {
        padding: 5px 10px;
        text-indent: 5px;
        font-size: 12.6pt;
        border-radius: 5px;
        border: solid 1.5px #bdbdbd;
        // box-shadow: 0 0 5px 0.2px #D3D3D3;
      }
    }
    .search-bar input {
      box-shadow: none;
    }

    .search-bar:visited {
      outline: none !important;
      box-shadow: none !important;
    }
    .back-btn {
      display: flex;
      margin: 0 5px;
      border: 1px solid;
      border-radius: 5px;
      font-size: 14px;
      letter-spacing: 0.14px;
      background-color: $orange-color;
      color: $white-color;
      height: 40px;
      padding: 10px 15px;
      align-items: center;
      font-weight: 800 !important;
    }
  }
}

.table-refund {
  .ant-table-thead {
    tr {
      th {
        background-color: var(--primary-6);
        color: $white-color;
      }
    }
  }
  .view-more {
    font-style: normal;
    font-weight: bold; /* For bold text */
    font-size: 14px;
    font-style: normal;
    color: #2e8efc;
    text-decoration: underline;
    cursor: pointer;
  }
}

.refund-confirm-modal {
  top: 44% !important;
  padding: 50px;
  font-weight: bold; /* For bold text */
  font-size: 1.5rem;
  line-height: 10px;
  font-style: normal;
  .confirm-refund {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold; /* For bold text */
    font-size: 2rem;
    line-height: 25px;
    font-style: normal; /* Ensures normal style */
  }
  .ant-modal-confirm-btns {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    .ant-btn-primary {
      background-color: #5d2af5;
      margin-bottom: 5px !important;
      border-radius: 5px;
      height: 45px;
    }
    .ant-btn-default {
      background-color: white;
      border: none;
      margin-bottom: 5px !important;
    }
  }
}
