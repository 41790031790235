@import "/src/styles/index.scss";

.notfound {
  background: #f5f1e6;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-weight: bold; /* For bold text */
    font-size: 24px;
    line-height: 36px;
    font-style: normal; /* Ensures normal style */
    letter-spacing: 0.24px;
    color: #2b2b2b;
    text-transform: uppercase;
    margin-top: 23px;
  }
  .btn-home-back {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    margin-top: 18px;
    width: 215px;
    height: 55px;
    letter-spacing: 0.14px;
    background: $yallow-color;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    font-weight: bold;
    a {
      color: #000000;
    }
  }
}
.maintenance {
  background: #f5f1e6;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-weight: bold; /* For bold text */
    font-size: 24px;
    line-height: 36px;
    font-style: normal; /* Ensures normal style */
    letter-spacing: 0.24px;
    color: #2b2b2b;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    margin-top: 23px;
  }
  h5 {
    margin-top: 9px;
    width: 552px;
    text-align: center;
    font-weight: bold; /* For bold text */
    font-size: 18px;
    line-height: 24px;
    font-style: normal; /* Ensures normal style */
    letter-spacing: 0.18px;
    color: #2b2b2b;
  }
}
