@import "/src/styles/index.scss";

.settle {
  display: flex;
  justify-content: space-between;

  .leftSide,
  .rightSide {
    flex: 1;
    margin: 0 10px;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    color: #389e0d;
    margin-bottom: 16px;
  }

  .total-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    background-color: #edf4ec;
    padding: 8px 12px;

    h3 {
      font-weight: 600;
    }

    .total-value {
      margin-left: auto;
      font-weight: 700;
      font-size: 16px;
      color: #389e0d;
    }
  }
}

.row-ER-GC {
  color: white;
  background-color: #4a6dd6fd;
}
.row-Credit-Card {
  color: white;
  background-color: #a8d8a1;
}
.row-Cash {
  color: white;
  background-color: #e2e29e;
}
.row-Discount {
  color: white;
  background-color: #f5f5e0;
}
.row-default {
  color: white;
  background-color: #f0f8ff;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #dfdfdf !important;
  opacity: 0.75;
  color: #090909 !important;
}

.btnConfirm {
  max-width: 356px;
  width: 100%;
  color: white;
  margin: 20px auto;
  border-radius: 4px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 8px 12px;
  font-size: 18px;
}

.display-none {
  display: none;
}

.tabTotal {
  .ant-table-thead > tr {
    display: none;
  }
  tr {
    background-color: #edf4ec;
  }
}