@import "/src/styles/index.scss";
.detail-payment-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.scrollable::-webkit-scrollbar {
  overflow-y: scroll;
  display: none !important; /* Ẩn thanh cuộn trên các trình duyệt WebKit (Chrome, Safari) */
}
.home-page {
  background: var(--bg-color-main);
  font-family: "Montserrat";
}
.board-wrapper {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  // flex-wrap: nowrap;
  .board-columns {
    max-width: 16%;
    flex: 0 0 16%;
    background-color: $red-color;
    height: calc(100vh - 135px);
    background-color: $bg-columns;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  
    .columns-header {
      height: 50px;
      padding: 12px 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .columns-header-left {
        display: flex;
        align-items: center;

        .columns-header-title {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.16px;
          letter-spacing: 0.14px;
          color: $white-color;
          font-weight: 800;
          padding: 0 10px;
        }
        .columns-header-count {
          width: 20px;
          height: 20px;
          display: inline-block;
          text-align: center;
          line-height: 20px;
          background-color: $white-color;
          font-size: 14px;
          color: $black-color;
          border-radius: 100%;
          font-weight: 700;
        }
      }
      .colums-header-right {
        .btn-print-order {
          border: 1px solid #ffffff;
          border-radius: 6px;
          padding: 3px 10px;
          // width: 73px;
          // height: 25px;
          font-size: 14px;
          color: $white-color;
          background: transparent;
        }
      }
    }
    &:nth-child(1) .columns-header {
      background-color: $yallow-color;
    }
    &:nth-child(2) .columns-header {
      background-color: $green-color-1;
    }
    &:nth-child(3) .columns-header {
      background-color: var( --secondary-2-default);
    }
    &:nth-child(4) .columns-header {
      background-color: var(--info-4-pressed);
    }
    &:nth-child(5) .columns-header {
      background-color: var(--secondary-4-pressed);
    }
    &:nth-child(6) .columns-header {
      background-color: var(--primary-6);
     
    }
   

    .colums-wrapper {
      padding-top: 12px;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 50px;
      &::-webkit-scrollbar {
        width: 0px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: transparent;
        background-clip: content-box;
      }
      background: var(--bg-color-main);
    }
  }
}

.loading_container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
}
