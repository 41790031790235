.search {
  display: flex;
  align-items: center;
  border: 1px solid var(--neutral-line);
  width: 250px;
  padding: 10px;
  border-radius: 6px;
  background-color: var(--neutral-base);
  &-input {
    border: none;
    flex: 1;
    background-color: var(--neutral-base);
  }
  img {
  }
}
