@import "../../styles/variables";
.ant-table-tbody > tr > td {
  background-color: #000;
  color: white;
}

.ant-table {
  background-color: #000;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #333 !important;
}
.modal-shipper {
  .ant-modal-title {
    padding-top: 2.1rem;
    padding-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      letter-spacing: 0.21px;
      line-height: 25px;
      font-size: 20px;
      color: $black-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-tag-has-color {
        margin-left: 10px;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 0.12px;
        color: #ffffff;
        height: 22px;
        border-radius: 4px;
      }
    }

    h5 {
      font-size: 12px;
      line-height: 14px;
      color: $black-color;
      font-weight: normal;
      padding-top: 5px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        height: 16px;
        width: 1px;
        background-color: #dcdcdc;
        margin: 0 7px;
      }
      b {
        font-weight: normal;
        color: $red-color;
      }
    }
  }
  .ant-modal-content {
    height: 710px;
    border-radius: 17px !important;
    padding-bottom: 17px;
    .ant-modal-body {
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 0;
    }
  }
  .ant-modal-header {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 17px 17px 0 0;
    border-bottom: none;
  }
  .ant-modal-close {
    right: -65px;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    background-color: $red-color;

    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: $white-color;
        font-size: 17px;
      }
    }
  }
  .ant-modal-body {
    padding-top: 0;
    .ant-input-affix-wrapper-has-feedback {
      padding: "none";
    }
  }
  .ant-modal-footer {
    padding-bottom: 5px;
    padding-top: 5px;
    position: absolute;
    width: 100%;
    bottom: 5px;
  }
  input {
    padding: 8px 10px !important;
    border-radius: 5px !important;
    &::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
  .ant-select-selector {
    border-radius: 5px !important;
  }
  .ant-switch-checked {
    background-color: #3eafbf;
  }
}
.btn-submit {
  -webkit-box-shadow: 0px 12px 17px -9px rgba(0, 0, 0, 0.43) !important;
  box-shadow: 0px 12px 17px -9px rgba(0, 0, 0, 0.43) !important;
  &:hover,
  &:active,
  &:focus {
    border: none !important;
    color: $white-color !important;
  }
}
.account-confirm-modal {
  top: 35% !important;
  padding: 50px;
  font-weight: bold; /* For bold text */
  font-size: 1.5rem;
  line-height: 10px;
  font-style: normal;
  .confirm-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold; /* For bold text */
    font-size: 2rem;
    line-height: 25px;
    font-style: normal; /* Ensures normal style */
    text-align: center;
  }
  .ant-modal-confirm-btns {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    .ant-btn-primary {
      background-color: #e31837;
      border: #e31837;
      margin-bottom: 5px !important;
      border-radius: 5px;
      height: 45px;
    }
    .ant-btn-default {
      background-color: white;
      border: none;
      margin-bottom: 5px !important;
    }
  }
}
.ant-table-cell-scrollbar {
  box-shadow: none !important;
}
